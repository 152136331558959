import React from "react"
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux'
import SideMenuDrawer from "./components/side-menu-drawer"
import DashboardSideMenu from "./components/side-menu/index"
import DashboardHeader from "./components/header"
import DashboardHeaderAdmin from "./components/header-admin"
import DashboardFooter from "./components/footer"
import Alerter from "./components/alerter"
import CartDrawer from "./components/cart-drawer"
import UserAction from 'redux/modules/user/actions'

const DashboardLayout = ({
	children
}) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { user, admin } = useSelector(({user}) => user);

	let dashboardHeader
	if(user){
		var role = (user.user) ? user.user.role : user.userData.role;
		if(role == 'client')
		{
			dashboardHeader = <DashboardHeader />
		} else {
			dashboardHeader = <DashboardHeaderAdmin />
		}
	}

	const handleAdminLogout = async () =>{ 
		dispatch(UserAction.toggleUser(false))
		localStorage.removeItem('selectedProgramId');
		// history.push('/admin/projects');
		history.goBack();
	}

	return (
		<React.Fragment>
			<div
				className={`flex flex-row items-center`}
			>
				<div className="sm:hidden md:hidden lg:block xl:block 2xl:block min-w-80">
					<div id="side-menu-wrapper">
						<DashboardSideMenu />
					</div>
				</div>
				<div className="sm:w-full md:w-full lg:w-fit xl:w-fit 2xl:w-fit">
					<div id="content-layout-wrapper">
						<div className="h-full">
							{dashboardHeader}
							{
								admin != null && <div className="h-8 p-1 px-11 flex justify-between" style={{background: "linear-gradient(to left, #EC413D, #FF683A)"}}>
									<p className="font-bold text-white uppercase">Warning: Admin Access</p>
									<p className="font-bold text-white uppercase cursor-pointer" onClick={handleAdminLogout}>Return to admin</p>
								</div>
							}
							<div id="dashboard-content-wrapper" className="p-1">
								<div id="dashboard-content-container" className="h-full overflow-auto content-horizontal-padding py-8 relative">
									<Alerter />
									{ children }
								</div>
							</div>

							<div className="sm:hidden md:hidden lg:block xl:block 2xl:block">
								<DashboardFooter />
							</div>
						</div>
					</div>
				</div>
			</div>
			<CartDrawer />
			<SideMenuDrawer />
		</React.Fragment>
		
	)

}

export default DashboardLayout