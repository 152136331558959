export const SET_PAGE_LOADER = 'SET_PAGE_LOADER'
export const SET_CURRENT_ROUTE = 'SET_CURRENT_ROUTE'
export const SET_SIDE_MENU_DRAWER_SHOW = 'SET_SIDE_MENU_DRAWER_SHOW'
export const SET_CURRENT_PAGE_MOUNTED = 'SET_CURRENT_PAGE_MOUNTED'
export const IMPORT_ORG_PROGRAMS = 'IMPORT_ORG_PROGRAMS'
export const SELECT_PROGRAM_ID = 'SELECT_PROGRAM_ID'

export const VIEW_ORG_DASHBOARD = 'VIEW_ORG_DASHBOARD'

export const SET_HEADER_YEAR = 'SET_HEADER_YEAR'
export const SET_ALERT = 'SET_ALERT'

export const SET_TIME_ESTIMATE = 'SET_TIME_ESTIMATE'

export const USE_CLEAR_CACHE = 'USE_CLEAR_CACHE'
export const TOGGLE_FDD_MODAL = 'TOGGLE_FDD_MODAL'
export const GET_ORGANIZATION_PAGINATE = 'GET_ORGANIZATION_PAGINATE'
